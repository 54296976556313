import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import data_science from "../Assets/Images/data_science.svg";
import robotics from "../Assets/Images/robotics.svg";
import web3 from "../Assets/Images/web3.svg";
import microsoft from "../Assets/Images/microsoft.png";
import { Link } from "react-router-dom";

const Innovations = () => {
    const data=[
        {
            title:"Data Science",
            desc:`At Ingenuity Analytics, we transform raw data into actionable
            insights using advanced analytics and machine learning. Our
            customized solutions help optimize operations, enhance customer
            experiences, giving your business a
            competitive edge.`,
            image:data_science,
            link:"/service/data-science"
        },
        {
            title:"Robotic Process Automation",
            desc:`Ingenuity Analytics specializes in RPA to streamline your workflows and boost productivity. By automating repetitive tasks, we reduce operational costs and minimize  errors, allowing your team to focus on strategic initiatives. `,
            image:robotics,
            link:"/service/robotics"
        },
        {
            title:"Web3 Development",
            desc:`At Ingenuity Analytics, Our Web3 Development service focuses on building decentralized applications and smart contracts using blockchain technology. These solutions ensure, transparent and efficient processes, enhancing trust and innovation. `,
            image:web3,
            link:"/service/web3"
        },
        {
            title:"Microsoft Power Platform",
            desc:`Use Microsoft Power Platform to build apps using Power Apps, automate tasks using Power Automate, analyze data using Power BI, and create websites using Power Pages.`,
            image:microsoft,
            width:"66px",
            height:"74px",
            link:"/service/microsoft-power-platform"
        },
    ]
  return (
    <Grid container spacing={2}>
      {
        data?.map((e,i)=>(
            <Grid item lg={3} md={4} xs={12} key={i} position={"relative"} mt={"2rem"} height={"100%"}>
            <Typography component={"img"} src={e?.image} sx={{position:"absolute",zIndex:"1",top:"-10px",left:"50%",transform:"translateX(-45%)",height:e.height&&e.height,width:e.width&&e.width}}/>
        <Box
          sx={{
            height:{lg:"430px"},
            clipPath:
              "polygon(28% 0%, 28% 14%, 72% 14%, 72% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)",
            background: "#fff",
            textAlign: "center",
            p: "0.7rem",
            borderRadius: "5px",
            transition: "0.5s ease",
            "&:hover": {
              background:
                "linear-gradient(to right, rgba(228, 17, 17, 0.3) 0%, rgba(255, 102, 102, 0.3) 22%, rgba(83, 231, 31, 0.3) 50%, rgba(57, 187, 11, 0.3) 83%)",
            },
          }}
        >
       
          <Typography
            sx={{
              fontFamily: "exoBold",
              fontSize: { md: "20px", xs: "20px" },
              color: "#000",
              pt: "3.5rem",
              pb: "1rem",
            }}
          >
            {e.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "exoRegular",
              fontSize: { md: "14px", xs: "14px" },
              color: "rgb(0 0 0 / 69%)",
              pb: "1.5rem",
              letterSpacing:"1.5px",
              lineHeight:"30px"
            }}
          >
           {e.desc}
          </Typography>
          <Button component={Link} to={e.link}
            sx={{
              mb: "1rem",
              textTransform: "capitalize",
              fontFamily: "exoBold",
              // lineHeight:"0",
              background: "#39BB0B",
              borderRadius: "5px",
              color: "#fff",
              fontSize: "16px",
              p: "8px 30px",
              gap: "0.5rem",
              "&:hover": {
                background: "#53E71F",
              },
            }}
          >
            Know more
          </Button>
        </Box>
      </Grid>
        ))
      }
    </Grid>
  );
};

export default Innovations;
