import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import textbg from "../../Assets/Images/textbg.png";
import buildings from "../../Assets/Images/buildings.png";
import about_hero from "../../Assets/Images/about_hero.png";
import about_2 from "../../Assets/Images/About_2.gif";
import about_1 from "../../Assets/Images/About_1.gif";
import vision_img from "../../Assets/Images/vision_img.png";
import vision_bg from "../../Assets/Images/vision_bg.png";
import commitment_img from "../../Assets/Images/commitment_img.png";
import about_msg_bg from "../../Assets/Images/about_msg_bg.png";
import dot from "../../Assets/Images/dot.gif";
import arrow from "../../Assets/Images/arrow.gif";
import AboutForm from "../../Components/AboutForm";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Navbar />
      <Container>
        <Box py={"2rem"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                backgroundImage: `url(${textbg})`,
                backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                p: "10px 40px",
                borderLeft: "6px solid #E41111",
                borderRight: "6px solid #39BB0B",
                lineHeight: "0",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontFamily: "exoBold",
                  fontSize: { sm: "30px", xs: "22px" },
                  background:
                    "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Who We Are
              </Typography>
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "exoRegular",
              fontSize: { sm: "20px", xs: "18px" },
              textAlign: "center",
              color: "rgb(0 0 0 / 78%)",
              py: "2rem",
            }}
          >
            Welcome to Ingenuity Analytics, where innovation meets expertise. We
            are a forward-thinking tech company dedicated to transforming
            businesses through cutting-edge technology and data-driven
            solutions.
          </Typography>
        </Box>
      </Container>
      <Typography component={"img"} src={buildings} width={"100%"} />
      <Box
        sx={{
          mt: "-10px",
          lineHeight: "0",
          backgroundImage: `url(${about_hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          p: "4rem 1rem",
        }}
      >
        <Container>
          <Typography
            sx={{
              fontFamily: "exoMedium",
              fontSize: { sm: "16px", xs: "16px" },
              color: "rgb(0 0 0 / 78%)",
              textAlign:{md:"start",xs:"center"}
            }}
          >
            We are
          </Typography>
         <Typography sx={{textAlign:{md:"start",xs:"center"},pt:"0.5rem"}}>
         <Typography
            component={"span"}
            sx={{
              fontFamily: "exoBold",
              fontSize: { sm: "40px", xs: "30px" },
              letterSpacing: "8px",
              background:
                "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            A Vibrant Collective of Digital Pioneers!
          </Typography>
         </Typography>
          <Box pt={"2rem"}>
            <Typography component={"img"} src={about_2} sx={{position:"relative",top:{md:"3rem"},width:{md:"500px",xs:"200px"}}} />
          </Box>
          <Box textAlign={"end"}>
            <Typography component={"img"} src={about_1} sx={{width:{md:"200px",xs:"80px"}}} />
          </Box>
        </Container>
      </Box>
      {/* vision  */}
      <Box
        my={"2rem"}
        sx={{
          backgroundImage: `url(${vision_bg})`,
          backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: "2rem",
        }}
      >
        <Container>
          <Typography textAlign={"center"}>
            <Typography
              component={"span"}
              position={"relative"}
              sx={{
                fontFamily: "exoBold",
                fontSize: { sm: "30px", xs: "22px" },
                background:
                  "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Vision
              <Typography
                component={"img"}
                src={dot}
                sx={{
                  position: "absolute",
                  top: "-30px",
                  width: "50px",
                  right: "-50px",
                }}
              />
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontFamily: "exoRegular",
              textAlign: "center",
              fontSize: { sm: "18px", xs: "16px" },
              color: "rgb(0 0 0 / 69%)",
            }}
          >
            At Ingenuity Analytics, we envision a future where technology
            seamlessly integrates with business operations to unlock new
            potential and create unprecedented opportunities. We strive to be at
            the forefront of technological advancements, continuously exploring
            and implementing the latest innovations to help our clients stay
            ahead of the curve.
          </Typography>
          <Grid container spacing={2} py={"2rem"}>
            <Grid
              item
              md={5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{textAlign:{md:"start",xs:"center"}}}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "24px", xs: "16px" },
                      color: "rgb(0 0 0 / 100%)",
                    }}
                  >
                    Innovate Continuously
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                    }}
                  >
                    Strive to push the boundaries of technology and creativity
                    to deliver cutting-edge solutions.
                  </Typography>
                </Box>
                <Box pt={"2rem"}>
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "24px", xs: "16px" },
                      color: "rgb(0 0 0 / 100%)",
                    }}
                  >
                    Empower Growth
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                    }}
                  >
                    Enable businesses and individuals to reach their full
                    potential through transformative solutions and services
                  </Typography>
                </Box>
                <Box pt={"2rem"}>
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "24px", xs: "16px" },
                      color: "rgb(0 0 0 / 100%)",
                    }}
                  >
                    Drive Positive Impact
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                    }}
                  >
                    Make a meaningful difference in the world by using
                    technology to solve real-world problems and improve lives.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={7} sm={12} xs={12}>
              <Typography component={"img"} src={vision_img} width={"100%"} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* commitment  */}
      <Box
        mb={"2rem"}
        sx={{
          backgroundImage: `url(${vision_bg})`,
          backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: "2rem",
        }}
      >
        <Container>
          <Typography textAlign={"center"}>
            <Typography
              component={"span"}
              position={"relative"}
              sx={{
                fontFamily: "exoBold",
                fontSize: { sm: "30px", xs: "22px" },
                background:
                  "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Commitment
              <Typography
                component={"img"}
                src={dot}
                sx={{
                  position: "absolute",
                  top: "-30px",
                  width: "50px",
                  right: "-50px",
                }}
              />
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontFamily: "FRAHV",
              letterSpacing: "10px",
              textAlign: "center",
              fontSize: { sm: "48px", xs: "30px" },
              textFillColor: "transparent",
              textStroke: "1px #7D7D7D",
              WebkitTextFillColor: "transparent",
              WebkitTextStroke: "1px #7D7D7D",
              MozTextFillColor: "transparent",
            }}
          >
            EXCELLENCE IS OUR HABIT!
          </Typography>
          <Grid container spacing={2} py={"2rem"}>
            <Grid item md={7} sm={12} xs={12}>
              <Typography
                component={"img"}
                src={commitment_img}
                width={"100%"}
              />
            </Grid>
            <Grid
              item
              md={5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "exoRegular",
                    fontSize: { sm: "16px", xs: "16px" },
                    color: "rgb(0 0 0 / 69%)",
                    lineHeight: "2rem",
                    textAlign:{md:"start",xs:"center"}
                  }}
                >
                  Our mission is to deliver customized, impactful solutions that
                  address the unique challenges and goals of each client. We are
                  committed to fostering a culture of excellence, innovation,
                  and collaboration, ensuring that our services not only meet
                  but exceed expectations.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${about_msg_bg})`,
          backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: "3rem",
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                sx={{
                  fontFamily: "exoBold",
                  fontSize: { sm: "45px", xs: "30px" },
                  color: "rgb(0 0 0 / 100%)",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // lineHeight: "2rem",
                }}
              >
                Have questions?
                <Typography
                  component={"img"}
                  src={arrow}
                  width={"50px"}
                  height={"50px"}
                  sx={{ transform: {md:"rotate(-90deg)"} }}
                />
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontFamily: "exoBold",
                  fontSize: { sm: "60px", xs: "35px" },
                  background:
                    "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                We’re here to help!
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <AboutForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default About;
