import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";

const MicrosoftPowerPlatform = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box>
      <Navbar />
      <Container>
        <Box
          py={"2rem"}
          px={"1rem"}
          sx={{
            "& .title": {
              fontFamily: "exoBold",
              fontSize: { md: "24px", xs: "24px" },
              color: "#000",
              textAlign: { sm: "start", xs: "center" },
            },
            "& .para": {
              pb: "1rem",
              lineHeight: "30px",
              fontFamily: "exoRegular",
              fontSize: { md: "16px", xs: "16px" },
              color: "#000",
            },
          }}
        >
         <Box textAlign={"center"} pb={"1rem"}>
        <Typography component={"img"} src="	https://miro.medium.com/v2/resize:fit:720/format:webp/1*fK9djF8KQqw3fzwQwBk4Rg.png" sx={{
            width:{md:"auto",xs:"100%"}
          }} />
        </Box>
          <Typography className="title">Introduction</Typography>
          
          <Typography className="para">
          The Microsoft Power Platform is a powerful suite of applications designed to help organizations automate processes, build solutions, analyze data, and create virtual agents. It empowers users to create solutions with little to no coding knowledge, promoting rapid development and deployment.
          </Typography>
       
          <Typography className="para"><b>Key Components</b></Typography>
          <Typography className="para" pl={"0.5rem"}>
          1. Power Apps
          </Typography>
          <Typography className="para" pl={"0.5rem"}>
          2. Power Automation
          </Typography>
          <Typography className="para" pl={"0.5rem"}>
          3. Power BI
          </Typography>
          <Typography className="para" pl={"0.5rem"}>
          4. Power virtual Agents
          </Typography>
          <Typography className="para" pl={"0.5rem"}>
          5. Power Pages
          </Typography>
          <Typography className="title">
          1. Power Apps
          </Typography>
          <Typography className="para">
          Enables users to build custom apps with a no-code or low-code approach.
          </Typography>
          <Typography className="para">
          <b>Features:</b>
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Drag-and-Drop Interface:</b> Simplifies the app-building process with a visual designer.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Pre-Built Templates:</b> Offers templates to quickly start common types of apps.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Data Integration:</b> Connects seamlessly with data sources like SharePoint, Excel, and SQL Server.
          </Typography>
          <Typography className="title">
          2. Power Automate
          </Typography>
          
          <Typography className="para">
          Allows users to automate workflows between various applications and services.
          </Typography>
          <Typography className="para">
          <b>Features:</b>
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Easy-to-Use Interface:</b> Provides a visual designer to create workflows with triggers and actions.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Predefined Actions:</b> Includes actions for different services, such as sending emails, updating records, or posting to social media.
          </Typography>
          <Typography className="title">
          3. Power BI
          </Typography>
          
          <Typography className="para">
          Provides data analytics and visualization tools to help users make informed business decisions.
          </Typography>
          <Typography className="para">
          <b>Features:</b>
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Interactive Dashboards:</b> Allows users to create and share dashboards that display key metrics and trends.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Data Transformation:</b> Includes tools to clean, transform, and model data.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Integration:</b> Connects to various data sources for comprehensive data analysis.
          </Typography>
          <Typography className="title">
          4. Power Virtual Agents
          </Typography>
          
          <Typography className="para">
          Enables users to create chatbots without writing code.
          </Typography>
          <Typography className="para">
          <b>Features:</b>
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Graphical Interface:</b> Provides a visual designer to create and manage bot conversations.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Integration:</b> Connects with Microsoft Teams and other services to automate responses and actions.
          </Typography>
          <Typography className="title">
           5. Power Pages
          </Typography>
          
          <Typography className="para">
          Allows users to create, manage, and publish secure, responsive websites with no-code/low-code tools.
          </Typography>
          <Typography className="para">
          <b>Features:</b>
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Drag-and-Drop Design:</b> Simplifies website creation with a visual editor.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Pre-Built Templates:</b> Offers templates for common types of websites, such as customer portals or event registration sites.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Data Integration:</b> Seamlessly integrates with Dataverse and supports custom code using HTML, CSS, and JavaScript.
          </Typography>
          <Typography className="title">
          Conclusion
          </Typography>
          <Typography className="para">
          The Microsoft Power Platform is a versatile and powerful suite of tools that democratizes the creation of business applications, automation of workflows, analysis of data, and development of virtual agents. By enabling users to build solutions with minimal coding, it accelerates development, promotes innovation, and enhances efficiency across organizations. Its seamless integration with other Microsoft products and services, combined with robust security and scalability, makes it an essential tool for modern businesses looking to streamline operations and drive growth.
          </Typography>
        
          
       
     
          
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default MicrosoftPowerPlatform;
