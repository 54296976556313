import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import data_science from "../Assets/Images/data_science.svg";
import web3 from "../Assets/Images/web3.svg";
import robotics from "../Assets/Images/robotics.svg";
import achievment_bg from "../Assets/Images/achievment_bg.png";
import arrow from "../Assets/Images/arrow.gif";
import microsoft from "../Assets/Images/microsoft.png";

const achievements = [
  {
    image: data_science,
    title: "Data Science",
    id:"data_science",
  },
  {
    image: robotics,
    title: "Robotic Process\nAutomation",
     id:"robotics"
  },
  {
    image: web3,
    title: "Web3\nDevelopment",
     id:"web3"
  },
  {
    image:microsoft,
     id:"microsoft",
    width:"66px",
    height:"74px",
    title: " Microsoft\n Power Platform",
  },
];

const AchievmentBox = () => {
  return (
    <Grid container spacing={2} py={"2rem"}>
      {achievements.map((achievement, index) => (
        <Grid item md={3} sm={3} xs={12} key={index}>
          <Box component={"a"} href={`#${achievement.id}`}
            sx={{
              display:"block",
              textDecoration:"none",
              backgroundImage: `url(${achievment_bg})`,
              width:"100%",
              margin:"0 auto",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              textAlign: "center",
              p: "1rem",
              borderRadius: "10px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Typography component={"img"} src={achievement.image} sx={{
              height:achievement.height&&achievement.height,width:achievement.width&&achievement.width
            }} />
            <Typography
              sx={{
                fontFamily: "exoBold",
                fontSize: { sm: "20px", xs: "20px" },
                color: "rgb(0 0 0 / 100%)",
                textAlign: { xs: "center" },
                height: "60px",
              }}
            >
              {achievement.title.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Typography
              component={"img"}
              src={arrow}
              mt="1rem"
              width={"50px"}
              height={"50px"}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default AchievmentBox;
