import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import DataScience from "./Pages/Services/DataScience";
import MicrosoftPowerPlatform from "./Pages/Services/MicrosoftPowerPlatform";
import Web3 from "./Pages/Services/Web3";
import Robotics from "./Pages/Services/Robotics";


function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/service/data-science" element={<DataScience />} />
        <Route path="/service/microsoft-power-platform" element={<MicrosoftPowerPlatform />} />
        <Route path="/service/web3" element={<Web3 />} />
        <Route path="/service/robotics" element={<Robotics />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;