import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grow } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ContactForm from "./ContactForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      timeout={800}
      easing="cubic-bezier(0.4, 0, 0.2, 1)"
      {...props}
    />
  );
});

export default function ContactModal({ setOpen, open }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        maxWidth="sm" // Sets the maximum width of the Dialog
        fullWidth
        PaperProps={{
          sx: {
            margin: "auto", // Centers the dialog horizontally
            height: "auto", // Adjusts height based on content
          },
        }}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "transparent",
            boxShadow: "none",
            p:"0 !important"
            
          }}
        >
          <Toolbar sx={{
            borderBottom:"1px solid #455A64"
          }}>
            <Typography
              sx={{ flex: 1, color: "#000000" }}
              variant="h6"
              component="div"
            >
                Contact Us!
            </Typography>
            <IconButton
              edge="end"
              color="#000000"
              sx={{
                background:"#F1FAFF"
              }}
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Box sx={{
            p:{lg:"0 2rem 0rem",xs:"0 1rem 0rem"},
          }}>
          <ContactForm  text={"center"}/>
          </Box>
        </AppBar>
      </Dialog>
    </React.Fragment>
  );
}
