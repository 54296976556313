import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";

const Web3 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box>
      <Navbar />
      <Container>
        <Box
          py={"2rem"}
          px={"1rem"}
          sx={{
            "& .title": {
              fontFamily: "exoBold",
              fontSize: { md: "24px", xs: "24px" },
              color: "#000",
              textAlign: { sm: "start", xs: "center" },
            },
            "& .para": {
              pb: "1rem",
              lineHeight: "30px",
              fontFamily: "exoRegular",
              fontSize: { md: "16px", xs: "16px" },
              color: "#000",
            },
          }}
        >
          <Box textAlign={"center"} pb={"1rem"}>
            <Typography
              component={"img"}
              src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*bp7d1Q-Ace9yg1Kr.png"
              sx={{
                width: { md: "auto", xs: "100%" },
              }}
            />
          </Box>
          <Typography className="para">
            The internet has come a long way since its inception. From simple
            static web pages to complex web applications, it has transformed the
            way we live, work, and connect with each other. However, as the
            internet evolved, so did the concerns about centralization, data
            privacy, and trust in online systems. This is where Web3 development
            comes into play, offering a new paradigm for building decentralized
            applications (dApps) that aim to address these issues.
          </Typography>
          <Typography className="title">
            Introduction to Web3 Development
          </Typography>

          <Typography className="para">
            Web3, short for “Web 3.0,” represents the next generation of the
            internet, one that is decentralized, user-centric, and trustless.
            Unlike the current Web 2.0, where large corporations and central
            authorities control data and user interactions, Web3 aims to empower
            individuals and communities through blockchain technology, smart
            contracts, and peer-to-peer networks.
          </Typography>
          <Box textAlign={"center"} pb={"1rem"}>
            <Typography
              component={"img"}
              src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*-rIMQdv4ee8ZXdQKxT36kQ.png"
              sx={{
                width: { md: "auto", xs: "100%" },
              }}
            />
          </Box>
          <Typography className="para">
            Web3 development involves creating applications that run on
            decentralized networks and leverage blockchain technology to enable
            trust, transparency, and user ownership. These applications can
            range from decentralized finance (DeFi) platforms, non-fungible
            token (NFT) marketplaces, decentralized social networks, to supply
            chain management systems — the possibilities are virtually
            limitless.
          </Typography>
          <Typography className="title">
            Tools and Frameworks for Building Decentralized Applications
          </Typography>
          <Box textAlign={"center"} py={"1rem"}>
            <Typography
              component={"img"}
              src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*grWR00fDdWqaL4Rd.jpg"
              sx={{
                width: { md: "auto", xs: "100%" },
              }}
            />
          </Box>
          <Typography className="para">
            To embark on your journey as a Web3 developer, you’ll need the right
            tools and frameworks. Here are some essential ones to get you
            started:
          </Typography>
          <Typography className="title">1. Ethereum</Typography>
          <Typography className="para">
            Ethereum is one of the most popular blockchain platforms for Web3
            development. It provides a robust ecosystem for creating smart
            contracts and decentralized applications. You can use Solidity, a
            programming language specifically designed for Ethereum, to write
            smart contracts.
          </Typography>
          <Typography className="title">2. Truffle</Typography>
          <Typography className="para">
            Truffle is a development framework for Ethereum that simplifies
            smart contract development, testing, and deployment. It comes with a
            suite of tools that make it easier to manage your dApp’s development
            lifecycle.
          </Typography>
          <Typography className="title">3. Remix</Typography>
          <Typography className="para">
            Remix is a web-based IDE (Integrated Development Environment)
            specifically tailored for Ethereum smart contract development. It
            provides a user-friendly interface for writing, testing, and
            deploying smart contracts.
          </Typography>
          <Typography className="title">
            4. IPFS (InterPlanetary File System)
          </Typography>
          <Typography className="para">
            IPFS is a decentralized file storage system that allows you to store
            and retrieve data in a peer-to-peer network. It’s an excellent
            choice for dApps that require distributed and immutable data
            storage.
          </Typography>
          <Typography className="title">5. Web3.js and ethers.js</Typography>
          <Typography className="para">
            Web3.js and ethers.js are JavaScript libraries that provide the
            tools to interact with Ethereum and other blockchain networks. They
            enable you to build the frontend of your dApp and connect it to the
            blockchain.
          </Typography>
          <Typography className="title">6. Hardhat</Typography>
          <Typography className="para">
            Hardhat is an Ethereum development environment that streamlines
            smart contract testing, deployment, and development. It’s known for
            its extensibility and developer-friendly features.
          </Typography>

          <Typography className="title">
            Resources and Tutorials for Aspiring Web3 Developers
          </Typography>
          <Box textAlign={"center"} py={"1rem"}>
            <Typography
              component={"img"}
              src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*b58fwy2u-myqse65.png"
              sx={{
                width: { md: "auto", xs: "100%" },
              }}
            />
          </Box>
          <Typography className="para">
            Getting started with Web3 development can be daunting, but there are
            plenty of resources and tutorials available to help you on your
            journey:
          </Typography>
          <Typography className="title">
            1. Ethereum Developer Documentation
          </Typography>
          <Typography className="para">
            The official Ethereum website provides comprehensive documentation,
            tutorials, and guides to get you started with Ethereum development.
          </Typography>
          <Typography className="title">2. CryptoZombies</Typography>
          <Typography className="para">
            CryptoZombies is an interactive coding tutorial that teaches you how
            to build Ethereum dApps by creating a zombie-battling game. It’s a
            fun way to learn the basics of smart contract development.
          </Typography>
          <Typography className="title">3. Solidity Documentation</Typography>
          <Typography className="para">
            Solidity is the programming language used for writing Ethereum smart
            contracts. The Solidity documentation is a valuable resource for
            understanding its syntax and features.
          </Typography>
          <Typography className="title">
            4. Coursera’s Blockchain Specialization
          </Typography>
          <Typography className="para">
            Coursera offers a specialization in blockchain technology that
            covers various aspects of Web3 development, including Ethereum,
            smart contracts, and decentralized applications.
          </Typography>
          <Typography className="title">
            5. Crypto Twitter and Forums
          </Typography>
          <Typography className="para">
            Follow blockchain developers on Twitter and participate in forums
            like Ethereum Stack Exchange to stay updated on the latest
            developments and seek help from the community.
          </Typography>
          <Typography className="title">Conclusion</Typography>
          <Typography className="para">
            In conclusion, Web3 development represents a revolutionary shift in
            how we build and interact with digital applications. By harnessing
            blockchain technology and decentralized networks, Web3 developers
            have the potential to shape the future of the internet, making it
            more secure, transparent, and user-centric. So, grab your tools,
            explore the resources, and start building the decentralized
            applications of tomorrow!
          </Typography>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Web3;
