import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";

const Robotics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box>
      <Navbar />
      <Container>
        <Box
          py={"2rem"}
          px={"1rem"}
          sx={{
            "& .title": {
              fontFamily: "exoBold",
              fontSize: { md: "24px", xs: "24px" },
              color: "#000",
              textAlign: { sm: "start", xs: "center" },
            },
            "& .para": {
              pb: "1rem",
              lineHeight: "30px",
              fontFamily: "exoRegular",
              fontSize: { md: "16px", xs: "16px" },
              color: "#000",
            },
          }}
        >
       
          <Typography className="para">
          Robotic process automation (RPA) is the use of software with artificial intelligence (AI) and machine learning capabilities to handle high-volume, repeatable tasks that previously required humans to perform. These tasks can include queries, calculations and maintenance of records and transactions.
          </Typography>
          <Typography className="para">
          RPA replicates human actions to handle repetitive tasks that normally involves human efforts fully engrossed in completing them without contributing to ROI. RPA, when used will save tremendous efforts, time and cost allowing humans to be more productive and contribute to business value.
          </Typography>
          <Typography className="para">
          RPA technology, sometimes called a software robot or bot, mimics a human worker, logging into applications, entering data, calculating and completing tasks and logging out.RPA provides organizations with the ability to reduce staffing costs and human error. Bots are typically low-cost and easy to implement, requiring no custom software or deep systems integration.
          </Typography>
          <Typography className="para">
          RPA evolved from three key technologies: screen scraping, workflow automation and artificial intelligence.
          </Typography>
          <Typography className="para">
          Screen scraping is the process of collecting screen display data from a legacy application so that the data can be displayed by a more modern user interface.
          </Typography>
          <Typography className="para">
          The advantages of workflow automation software, which eliminates the need for manual data entry and increases order fulfillment rates, include increased speed, efficiency and accuracy.
          </Typography>
          <Typography className="para">
          Lastly, artificial intelligence involves the ability of computer systems to perform tasks that normally require human intervention and intelligence.
          </Typography>
          <Typography className="title">
          BENEFITS OF RPA
          </Typography>

          <Typography className="para">
          Robotic process automation technology can help organizations on their digital transformation journeys by:
          </Typography>
    
          <Typography className="para">
        1. Enabling better customer service.
          </Typography>
          <Typography className="para">
         2. Ensuring business operations and processes comply with regulations and standards.
          </Typography>
          <Typography className="para">
         3. Allowing processes to be completed much more rapidly.
          </Typography>
          <Typography className="para">
         4. Providing improved efficiency by digitizing and auditing process data.
          </Typography>
         
          <Typography className="para">
         5. Creating cost savings for manual and repetitive tasks.
          </Typography>
          <Typography className="para">
         6. Enabling employees to be more productive.
          </Typography>
        
         
          <Box textAlign={"center"} py={"1rem"}>
            <Typography
              component={"img"}
              src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*QxcVJga8XudFF-hkjF6GBw.png"
              sx={{
                width: { md: "auto", xs: "100%" },
              }}
            />
          </Box>
          <Typography className="title">
          APPLICATION OF RPA
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Customer Service:</b> RPA can help companies offer better customer service by automating contact center tasks, including verifying e-signatures, uploading scanned documents and verifying information for automatic approvals or rejections.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Accounting:</b> Organizations can use RPA for general accounting, operational accounting, transactional reporting and budgeting.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Financial Services:</b> Companies in the financial services industry can use RPA for foreign exchange payments, automating account openings and closings, managing audit requests and processing insurance claims.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Healthcare:</b> Medical organizations can use RPA for handling patient records, claims, customer support, account management, billing, reporting and analytics.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Human Resources:</b> RPA can automate HR tasks, including onboarding and offboarding, updating employee information and timesheet submission processes.
          </Typography>
          <Typography className="para" component={"li"}>
          <b>Supply Chain Management:</b> RPA can be used for procurement, automating order processing and payments, monitoring inventory levels and tracking shipments.
          </Typography>
             </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Robotics;
