import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import home_banner from "../Assets/Images/home_banner.png";
import home_banner_mob from "../Assets/Images/home_banner_mob.png";
import bg from "../Assets/Images/bg.png";
import bg_mobile from "../Assets/Images/bg_mobile.png";
import learn from "../Assets/Images/learn.svg";
import consult from "../Assets/Images/consult.svg";
import learn_ from "../Assets/Images/learn_.svg";
import consult_ from "../Assets/Images/consult_.svg";
// import circle from "../Assets/Images/circle.gif";
import textbg from "../Assets/Images/textbg.png";
import img_1 from "../Assets/Images/img_1.png";
import img_2 from "../Assets/Images/img_2.png";
import img_3 from "../Assets/Images/img_3.png";
import frame from "../Assets/Images/frame.gif";
import gif_1 from "../Assets/Images/gif_1.gif";
import gif_2 from "../Assets/Images/gif_2.gif";
import gif_3 from "../Assets/Images/gif_3.gif";
import gif_4 from "../Assets/Images/gif_4.gif";
import flow_ from "../Assets/Images/flow_.gif";
import Innovations from "../Components/Innovations";
import Footer from "../Components/Footer";
import SubscribeBox from "../Components/SubscribeBox";
import dot from "../Assets/Images/dot.gif";
import img_bg from "../Assets/Images/img_bg.png";
import ContactModal from "../Components/ContactModal";
import { Link } from "react-router-dom";
// import Group from "../Assets/Images/Group.svg";

const Home = () => {
  const [open,setOpen]=useState(false)
  return (
    <Box>
      <Box
        sx={{
          background: "rgb(255 255 255 / 48%)",
        }}
      >
        <Navbar />
      </Box>
      <Box
        sx={{
          backgroundImage: {sm:`url(${home_banner})`,xs:`url(${home_banner_mob})`},
          backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "100% 100%",
          backgroundRepeat: "no-repeat",
          p: { lg: "5rem", xs: "0rem" },
          py: { md: "5rem",xs:"4rem" },
          pb:{sm:"auto",xs:"2rem"},
          lineHeight: "0",
        }}
      >
        <Container sx={{ position: "relative" }}>
          <Grid container spacing={2}>
            <Grid item sm={2} xs={4}>
              <Typography
                component={"img"}
                src={gif_1}
                sx={{
                  width: "100%",
                  // position:"absolute",
                  // top:"-34%",
                  // left:"-3%"
                }}
              />
            </Grid>
            <Grid item sm={8} xs={4}></Grid>
            <Grid item sm={2} xs={4}>
              <Typography
                component={"img"}
                src={gif_2}
                sx={{
                  width: "100%",
                  // position:"absolute",
                  // top:"-34%",
                  // left:"-3%"
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundImage: {sm:`url(${bg})`,xs:`url(${bg_mobile})`},
              backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
              backgroundPosition: "100% 100%",
              backgroundRepeat: "no-repeat",
              lineHeight: "0",
              p:{sm: "4rem 2rem 3rem",xs:"4rem 1rem 3rem"},
              textAlign: "center",
              width: { lg: "90%",md:"95%",xs: "100%" },
              m: "0 auto",
              position: "relative",
            }}
          >
            <Typography
              component={"img"}
              src={frame}
              sx={{
                width:"110px",
                position: "absolute",
                left: "50%",
                top: {sm:"-70px",xs:"-90px"},
                transform: "translateX(-50%)",
              }}
            />
            <Typography
              sx={{
                fontFamily: "exoBold",
                fontSize: { md: "34px", xs: "25px" },
                color: "#fff",
              }}
            >
              Innovating the Future with Cutting-Edge Technology!
            </Typography>
            <Typography
              sx={{
                fontFamily: "exoMedium",
                fontSize: { md: "25px", xs: "20px" },
                color: "#fff",
              }}
            >
              Empower your business with seamless automation and insightful{" "}
              <br /> data-driven strategies.
            </Typography>
            <Box
              sx={{
                display: "flex",
                py: "1rem",
                justifyContent: "center",
                flexDirection: {
                  sm: "row",
                  xs: "column",
                  gap: "1rem",
                  "& button,a": {
                    textTransform: "capitalize",
                    fontFamily: "exoBold",
                    borderRadius: "5px",
                    color: "#fff",
                    fontSize: "16px",
                    padding: "10px 30px",
                    gap: "0.5rem",
                  },
                },
              }}
            >
              <Button component="a"
              href="#innovation"
                sx={{
                  background: "#E41111",
                  width: { sm: "300px" },
                  "&:hover": {
                    background: "#E41111",
                    "& .hover": {
                      display: "block",
                    },
                    "& .main": {
                      display: "none",
                    },
                  },

                  "& .hover": {
                    display: "none",
                  },
                }}
              >
                <Typography component={"img"} src={learn} className="main" />
                <Typography component={"img"} src={learn_} className="hover" />
                Learn more
              </Button>
              <Button
                sx={{
                  background: "#39BB0B",
                  width: { sm: "300px" },
                  "&:hover": {
                    background: "#53E71F",
                    "& .hover": {
                      display: "block",
                    },
                    "& .main": {
                      display: "none",
                    },
                  },
                  "& .hover": {
                    display: "none",
                  },
                }}
                onClick={()=>setOpen(true)}
              >
                <Typography component={"img"} src={consult} className="main" />
                <Typography
                  component={"img"}
                  src={consult_}
                  className="hover"
                />
                Schedule a Consultation!
              </Button>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={2} xs={4} mt={"0.5rem"}>
              <Typography
                component={"img"}
                src={gif_3}
                sx={{
                  width: "100%",
                  // position:"absolute",
                  // top:"-34%",
                  // left:"-3%"
                }}
              />
            </Grid>
            <Grid item sm={8} xs={4}></Grid>
            <Grid item sm={2} xs={4} mt={"0.5rem"}>
              <Typography
                component={"img"}
                src={gif_4}
                sx={{
                  width: "100%",
                  // position:"absolute",
                  // top:"-34%",
                  // left:"-3%"
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={"4rem"} position="relative">
        {/* <Typography component={"img"} src={circle} sx={{
            position:"absolute",width:{md:"216px",xs:"100px"},
            right:{md:"-70px",xs:"-40px"},
            top:{md:"30px",xs:"50px"}
          }} /> */}
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                backgroundImage: `url(${textbg})`,
                backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                p: "10px 20px",
                borderLeft: "6px solid #E41111",
                borderRight: "6px solid #39BB0B",
                lineHeight: "0",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontFamily: "exoBold",
                  fontSize: { sm: "30px", xs: "25px" },
                  background:
                    "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Our Innovative Solutions
              </Typography>
            </Typography>
          </Box>
          <Box
            py={"2rem"}
            sx={{
              fontFamily: "exoMedium",
              fontSize: { sm: "18px", xs: "15px" },
              color: "rgb(0 0 0 / 69%)",
              "& .MuiDivider-root::before, .MuiDivider-root::after": {
                borderTop: "1px dashed #39BB0B",
                width: { sm: "100%", xs: "15%" },
              },
              "& .MuiDivider-root": {
                whiteSpace: { sm: "nowrap", xs: "balance" },
              },
              "& .MuiDivider-wrapper": {
                px: { sm: "auto", xs: "0" },
              },
            }}
          >
            <Divider>
              Empower your business with seamless automation <br />
              and insightful data-driven strategies.
            </Divider>
          </Box>
          <Innovations />
        </Container>
      </Box>
      <Box id="innovation" pb={"4rem"} position="relative" sx={{
         backgroundImage: `url(${img_bg})`,
                backgroundSize: "contain", // Adjust as needed (contain, cover, etc.)
                backgroundRepeat: "no-repeat",
      }}>
       <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt:{md:"5rem",sm:"2rem",xs:"0rem"}
            }}
          >
            <Typography
              sx={{
                backgroundImage: {sm:`url(${textbg})`,xs:""},
                backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                p: {sm:"10px 30px",xs:"10px 0 5px"},
                borderLeft: "6px solid #E41111",
                borderRight: "6px solid #39BB0B",
                lineHeight: "0",
                textAlign:"center",
                width:{sm:"auto",xs:"100%"}
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontFamily: "exoBold",
                  fontSize: { sm: "30px", xs: "25px" },
                  background:
                    "linear-gradient(to right,#E41111 0, #FF6666 22%, #53E71F 50%, #39BB0B 83%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
               Who We Are
              </Typography>
            </Typography>
          </Box>
        <Container>
         
          <Typography
                sx={{
                  fontFamily: "exoMedium",
                  fontSize: { sm: "18px", xs: "16px" },
                  textAlign:"center",
                  py:{md:"1rem",sm:"1rem",xs:"0"},
                  pb:{md:"0rem",sm:"2rem",xs:"1rem"}
                }}
              >
              A Team of Visionaries Shaping the Future!
              </Typography>
         <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={3}>
          <Grid item md={6} sm={6} xs={6}>
          <Typography
                component={"img"}
                src={img_1}
                sx={{
                  width: "100%",
                }}
              />
          <Typography
          mt={"1rem"}
                component={"img"}
                src={img_2}
                sx={{
                  width: "100%",
                }}
              />
          </Grid>
          <Grid item md={6} sm={6} xs={6} sx={{
            display:"flex",
            alignItems:"center"
          }}>
          <Typography
                component={"img"}
                src={img_3}
                sx={{
                  width: "100%",
                }}
              />
          </Grid>
          </Grid>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{
            display:"flex",
            alignItems:"center"
          }}>
          <Box sx={{ textAlign: { md: "start", xs: "center" } }}>
                  <Typography
                    sx={{
                      fontFamily: "exoBold",
                      fontSize: { sm: "30px", xs: "25px" },
                      // lineHeight:"2.3rem",
                      color: "rgb(0 0 0 / 100%)",
                      textAlign: { md: "start", xs: "center" },
                      position:"relative"
                    }}
                  >
                   Innovation, 
                   <br/>
                   Excellence 
                   <br/>
                   and 
                   <br/>
                   Collaboration.
                   <Typography
                component={"img"}
                src={dot}
                sx={{
                  position: "absolute",
                  top: "-20px",
                  width: "80px",
                  right: {md:"50%",xs:"80px"},
                  transform:"translateX(50%)"
                }}
              />
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "exoRegular",
                      fontSize: { sm: "16px", xs: "16px" },
                      color: "rgb(0 0 0 / 69%)",
                      textAlign: { md: "start", xs: "center" },
                      py:"0.5rem",
                      width:{sm:"70%",xs:"100%"}
                    }}
                  >
                  We are a team of innovators and problem-solvers driven by a passion for harnessing the power of technology to transform businesses.
                  </Typography>
                  <Button component={Link} to={"/about"} sx={{
                    mt:"0.5rem",
                       textTransform: "capitalize",
              fontFamily: "exoBold",
              // lineHeight:"0",
              background: "#39BB0B",
              borderRadius: "5px",
              color: "#fff",
              fontSize: "16px",
              p: "8px 30px",
              gap: "0.5rem",
              "&:hover": {
                background: "#53E71F",
              },
                  }}>
                  Know more about us
                  </Button>
                </Box>
          </Grid>
         </Grid>
        </Container>
      </Box>
      <Box sx={{
           background:
                "linear-gradient(to right, rgba(228, 17, 17, 0.3) 0%, rgba(255, 102, 102, 0.3) 22%, rgba(83, 231, 31, 0.3) 50%, rgba(57, 187, 11, 0.3) 83%)",
                overflow:"hidden"
      }}>
      <Typography component={"img"} src={flow_} sx={{width:{md:"100%",sm:"180%",xs:"360%"}}}/>
      </Box>
   <Box position={"relative"}>
     {/* <Typography component={"img"} src={Group} sx={{position:"absolute",top:"50%",right:"5%",   transform: "translate(-5%, -50%)",zIndex:"-1"}} /> */}
   <Container>
     <SubscribeBox/>
     </Container>
   </Box>
      <Footer />
      <ContactModal open={open} setOpen={setOpen}/>
    </Box>
  );
};

export default Home;
